
import { Component, Prop, Vue } from 'vue-property-decorator'
import { supportingDocuments } from '@/pages/permits/forms/fields'
import { DateTime } from 'luxon'
import { PERMIT_FORM_MODES } from '@/utilities/constants'

const defaultValues = () => ({
  recentColourPhoto: null,
  hackneyCarriageVehicleLicence: null,
  hackneyCarriageVehicleLicenceExpiryDate: DateTime.now(),
  hackneyCarriageDriverLicence: null,
  hackneyCarriageDriverLicenceExpiryDate: DateTime.now(),
  hireAndRewardVehicleInsuranceCertificate: null,
  hireAndRewardVehicleInsuranceCertificateExpiryDate: DateTime.now(),
  motCertificate: null,
  motCertificateExpiryDate: DateTime.now(),
})

@Component
export default class SupportingDocumentsStep extends Vue {
  // props // ***
  @Prop({ required: true, type: Object })
  location!: Record<string, any>
  @Prop({ type: Boolean })
  motCertificateRequired!: boolean
  @Prop({ type: Boolean })
  showSkip!: boolean

  // data // ***
  continueAttempted = false
  previewImage: Record<string, any> = { file: null, src: '' }
  reader: FileReader = new FileReader()
  values: Record<string, any> = defaultValues()

  // computed // ***
  get fields(): Record<string, any> {
    return supportingDocuments(this)
  }
  get loading(): boolean {
    return false
  }
  get mode() {
    return PERMIT_FORM_MODES.APPLY
  }
  get accept() {
    return '.pdf,.jpg,.png,.jpeg'
  }

  // methods // ***
  onBack() {
    this.$emit('back')
  }
  onCancel() {
    this.$emit('cancel')
  }
  async onContinue() {
    this.continueAttempted = true
    const valid = await this.validate()
    if (valid) this.$emit('continue', this.values)
  }
  onRecentColorPhotoInput(e: File) {
    this.previewImage.file = e
  }
  onSaveRecentColorPhotoInput() {
    if (!this.previewImage.file) return
    this.reader = new FileReader()
    this.reader.addEventListener('load', () => (this.previewImage.src = this.reader.result), false)
    this.reader.readAsDataURL(this.previewImage.file)
  }

  async onSkip() {
    const valid = await this.validate()
    if (valid) this.$emit('skip', this.values)
  }
  async validate(): Promise<boolean> {
    const refs: Record<string, any> = this.$refs
    const valid = await refs.form.validate()
    return !!valid
  }
}
